<template>
   <div class="PurchaseSettlement">
        <van-nav-bar
       title="买料结算"
       left-arrow
       fixed
       safe-area-inset-top
       placeholder
       @click-left="leftClick"
        />
        <!-- 内容区域 -->
        <div class="content">
            <!-- 第一个内容区域 -->
            <div class="ctnOne" v-if="BuyOrderList.bankInfo && BuyOrderList.status!=='DONE'">
                     <div class="zxjAccountNumber">
                         <h3>{{GLOBAL}}指定收款账号</h3>
                         <div class="flex">
                                <div class="text">{{BuyOrderList.payStatus == "WAIT"?'待付款':BuyOrderList.payStatus == "PAYED"?'已付款':'待确认'}}</div>
                         </div>
                     </div>

                     <!-- 收款账户 -->
                     <div class="flex margin" >
                         <div class="account flexLeftColor">收款账户：</div>
                         <div class="accountNumber flexRightColor" @click="copy(BuyOrderList.bankInfo.num)">{{BuyOrderList.bankInfo.num}}</div>
                     </div>
                     <!-- 收款银行 -->
                     <div class="flex margin">
                         <div class="account flexLeftColor">收款银行:</div>
                         <div class="accountNumber flexRightColor">{{BuyOrderList.bankInfo.bank}}</div>
                     </div>
                     <!-- 收款人 -->
                    <div class="flex margin">
                         <div class="account flexLeftColor">收款人:</div>
                         <div class="accountNumber flexRightColor">{{BuyOrderList.bankInfo.name}}</div>
                     </div>
              <!-- 开户支行 -->
              <div class="flex margin">
                <div class="account flexLeftColor">开户支行:</div>
                <div class="accountNumber flexRightColor">{{BuyOrderList.bankInfo.address}}</div>
              </div>
                     <!-- 截图凭证 -->
                    <div class="flex margin jietu">
                         <h3 style="color:'#666666'">上传付款凭证截图 <van-icon name="arrow" /></h3>
                     </div>
                     <van-uploader v-model="fileList" multiple :max-count="3" :after-read="afterRead" :before-delete="beforeDelete">
                        <div class="upload">
                            <div class="shu"></div>
                            <div class="heng"></div>
                        </div>
                     </van-uploader>
            </div>
            <!-- 第二个内容区域 -->
            <div class="ctnTwo" v-if="BuyOrderList.itemList">
                    <h3 class="ctnTwoH3">结算信息</h3>


                             <!-- 结算时间 -->
                     <div class="flex margin">
                         <div class="account flexLeftColor">结算时间:</div>
                         <div class="accountNumber flexRightColor">{{BuyOrderList.created | formatDate_}}</div>
                     </div>
                        <!-- 结算单号 -->
                     <div class="flex margin">
                         <div class="account flexLeftColor">结算单号:</div>
                         <div class="accountNumber flexRightColor">{{BuyOrderList.openNo}}</div>
                     </div>
                        <!-- 订单数量 -->
                     <div class="flex margin orderContent">
                         <div class="account flexLeftColor">订单数量:</div>
                         <div class="accountNumber flexRightColor">{{BuyOrderList.itemList.length}}</div>
                     </div>
                     <div class="jieSuanOrder" v-for="(item,index) in BuyOrderList.itemList" :key="index">
                                     <!-- 订单编号 -->
                                 <div class="flex margin jieSuanTime">
                                     <div class="account flexLeftColor">订单编号:</div>
                                     <div v-if="item.orderId" class="accountNumber flexRightColor" @click="openOrderIdentifier(item.orderId)">{{item.orderId}}  <van-icon name="arrow" /></div>
                                     <div v-if="!item.orderId" class="accountNumber flexRightColor">现价结算</div>

                                 </div>
                                    <!-- 重量 -->
                                 <div class="flex margin">
                                     <div class="account flexLeftColor">重量(克):</div>
                                     <div class="accountNumber flexRightColor">{{item.weight.toFixed(1)}}</div>
                                 </div>
                                     <!-- 单价 -->
                                 <div class="flex margin">
                                     <div class="account flexLeftColor">单价(元/克):</div>
                                     <div class="accountNumber flexRightColor">{{item.price}}</div>
                                 </div>
                       <!-- 保管费 -->
                       <div class="flex margin">
                         <div class="account flexLeftColor">保管费:</div>
                         <div class="accountNumber flexRightColor">{{item.storageFee ? item.storageFee : 0}}</div>
                       </div>
                                     <!-- 金额 -->
                                 <div class="flex margin orderContent">
                                     <div class="account flexLeftColor">金额(元):</div>
                                     <div class="accountNumber flexRightColor">{{item.storageFee ? item.money + item.storageFee : item.money}}</div>
                                 </div>
                     </div>
                     <!-- 合计 -->
                     <div class="flex margin total">
                         <h3 class="totalWeight">合计重量(克) <span>{{totalWeight.toFixed(1)}}</span> </h3>
                         <h3 class="totalMoney">合计金额(元) <span>{{totalMoney}}</span></h3>
                     </div>
            </div>
            <!-- 第三个区域内容 -->
            <div class="ctnThree" v-if="BuyOrderList.fetchInfo">
                         <!-- 取货信息 -->
                    <div class="flex margin quHuoInfo">
                         <h3>取货信息</h3>
                         <div class="accountNumber flexRightColor isFetched" :class="BuyOrderList.fetchInfo.fetched ? 'greenState' : ''">{{BuyOrderList.fetchInfo.fetched?'已取货':'未取货'}}</div>
                     </div>
                     <!-- 取货方式 -->
                     <div class="flex margin">
                         <div class="account flexLeftColor">取货方式:</div>
                         <div class="accountNumber flexRightColor">{{BuyOrderList.fetchInfo.type == "PLACE"?'自取':'快递'}}</div>
                     </div>
                     <!-- 取货地址 -->
                     <div class="flex margin">
                         <div class="account flexLeftColor">地址:</div>
                         <div class="accountNumber flexRightColor">{{BuyOrderList.fetchInfo.address.address}}</div>
                     </div>
                     <!-- 取货人 -->
                     <div class="flex margin">
                         <div class="account flexLeftColor">取货码:</div>
                         <div class="accountNumber flexRightColor">{{BuyOrderList.fetchInfo.fetchCode ? BuyOrderList.fetchInfo.fetchCode : '--'}}</div>
                     </div>
                     <!-- 联系电话 -->
                     <div class="flex margin">
                         <div class="account flexLeftColor">联系电话:</div>
                         <div class="accountNumber flexRightColor">{{BuyOrderList.fetchInfo.address.phone}}</div>
                     </div>
                     <!-- 备注 -->
                     <div class="flex margin">
                         <div class="account flexLeftColor">备注:</div>
                         <div class="accountNumber flexRightColor">{{BuyOrderList.fetchInfo.remark}}</div>
                     </div>
                     <!-- 料部电话 -->
                     <div class="flex margin">
                         <div class="account flexLeftColor">料部电话:</div>
                         <div class="accountNumber flexRightColor">18126327787</div>
                     </div>
            </div>
        </div>
        <footer>说明:由于货款入账审核需要一定时间,如果您已付货款,只需耐心等待审核人员审核即可,任何疑问可直接拨打客服热线:18126327787</footer>
   </div>
</template>

<script>
import * as api from'@/utils/api'
import axios from 'axios'
export default {
    data() {
        return {
            // 绑定已经上传的文件列表
            fileList:[],
            //  上传图片至服务器后返回的key值
             fileKey:[],
            fileBaseUrl:process.env.NODE_ENV == "dev"
                ? "/shenzhenyht"
                : "https://yuechengjinye.oss-cn-shenzhen.aliyuncs.com",
            localtoken:localStorage.getItem("token"),
            openId:'',
            BuyOrderList:{},
            totalWeight:0,
            totalMoney:0
        }
    },
    methods:{
        // 订单编号按钮点击事件
        openOrderIdentifier(order){
            this.$router.push({
                name:'buyOrderParticulars',
                query:{order:order,}
            })
        },
        // before-delete 删除前的回调函数
        beforeDelete(){
            this.fileList=[]
        },
        // 文件上传完毕之后的回调,获取到对应的 file 对象
       async  afterRead(file){
        console.log('1',file);
             const that = this;
                const data = {
                       size: 1
                     };
             api.apiFile(data).then((res) => {
                console.log('res',res);
                this.fileKey.push(res[0].key);
                console.log('key',this.fileKey);
                console.log(this.fileList);
                that.uploadFile(file.content, res[0].url);
        });
        },
          // 第二步上传文件
      uploadFile(imgpath,url) {
             // debugger;
             // 去掉url域名 保留后面
             const urllast =
               "/" +
               url
                 .split("/")
                 .slice(3)
                 .join("/");
             const configs = {
               headers: {
                 "Content-Type": "multipart/form-data",
                 Authorization: "Bearer " + this.localtoken
               }
             };
             const transformRequest = [
               function(data) {
                 // debugger;
                 let ret = "";
                 for (const it in data) {
                   ret +=
                     encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
                 }
                 return ret;
               }
             ];
             const imgarr = this.base64ToUint8Array(imgpath);
             axios({
               url: this.fileBaseUrl + urllast,
               method: "put",
               headers: {
                 "Content-Type": "application/octet-stream",
                 Authorization: "Bearer " + this.localtoken
               },
               data: imgarr
             }).then(async res => {
               let upload = await api.upLodaInfo(this.openId,{images:this.fileKey})
             });

             // axios.put(this.fileBaseUrl+urllast,imgarr,{configs,transformRequest}).then(res=>{
             //   console.log(res)
             // })
             // certifi.updaFileapi(this.fileBaseUrl+urllast,imgpath,{}).then(res=>{
             //   console.log(res)
             // })
        },
      base64ToUint8Array(base64String) {
            // debugger;
            const base64clear = base64String.substring(base64String.indexOf(",") + 1);
            const padding = "=".repeat((4 - (base64clear.length % 4)) % 4);
            const base64 = (base64clear + padding)
              .replace(/\-/g, "+")
              .replace(/_/g, "/");
            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
              outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        },
        // 封装方法获取买料结算单详情
async  getBuyOrderList(){
            // 发请求获取数据
            let res = await api.getBuyOrderList(this.openId)
            console.log(res);
            // 声明 总量 和 总价的 变量
                let weightAll = 0
                let moneyAll = 0
                // 遍历数据赋值
             res.itemList.forEach((item)=>{
                 weightAll += item.weight
                 moneyAll += item.money
             })
             res.payedImages.forEach(i=>{
            console.log('i',i);
          this.fileList.push({url:i.url,isImage: true})
        })
            //  将获取到数据赋值
            this.BuyOrderList = res
            this.totalWeight = weightAll
            this.totalMoney = res.storageFee ? moneyAll + res.storageFee : moneyAll

        },
        // 顶部左侧按钮点击事件
        leftClick(){
            this.$router.push({
                name:'buyOrder',
                query:{active:'buyJieSuan'}
            })
        },
        copy(txt){
                this.$copyText(txt).then(()=>{
                this.$toast('卡号已复制到剪贴板')
            })
        },
},
    created(){
        // 页面加载获取路由传递的值 用来发请求获取买料结算单详情
        this.openId = this.$route.query.openId
        // 调用封装方法获取买料结算单详情
        this.getBuyOrderList()


        console.log('1',this.fileList);

    }
}
</script>

<style lang="scss" scoped>
::v-deep{
     .van-nav-bar{
        height: 100px;
        .van-nav-bar__content{
        line-height: 100px;
        height: 100px;
          background: $YellowHeadBackgroundColor;
        .van-nav-bar__title{
            font-size: 40px;
          color: $YellowHeadColor;
        }
        .van-icon{
            font-size: 40px;
            color: $YellowHeadColor;
        }
        }
     }

     .van-uploader__preview-image {
             display: block;
             width: 140px;
             height: 150px;
             overflow: hidden;
     }
     .van-uploader__preview-delete {
             position: absolute;
             top: 0;
             right: 0;
             width: 30px;
             height: 30px;
             background-color: rgba(0,0,0,.7);
             border-radius: 0 0 0 30px;
     }
     .van-uploader__preview-delete-icon {
          position: absolute;
          top: 0;
          right: 0;
          color: #fff;
          font-size: 30px;
          -webkit-transform: scale(.5);
          transform: scale(.5);
     }
     .van-uploader__input-wrapper {
             width: 140px;
             height: 150px;
             border: 1px dotted #1A1A1A;
     }
}
.PurchaseSettlement{
  height: 100%;
    font-family: Microsoft YaHei;
    background-color: #f5f5f5;

}
.content{
    margin-top: 40px;
    width: 100%;
    //padding-bottom: 40px;
    h3{
            font-size: 30px;
            color: #212121;
            position: relative;
        }
    .ctnThree{
        background-color: #fff;
        width: 100%;
        padding: 8px 40px;
        margin-top: 30px;
    }
    .ctnTwo{
        background-color: #fff;
        width: 100%;
        padding: 8px 40px;
        margin-top: 30px;
        .ctnTwoH3{
            font-size: 32px;
            color: #212121;
            padding: 20px 0;
            border-bottom: 2px solid #E9E9E9 ;
            position: relative;
            left: -6px;
        }
        .orderContent{
            height: 70px;
        }
        .jieSuanTime{
            margin-top: 30px;
                .van-icon{
                     position: relative;
                     top: 3px;
                     right: -3px;
                }
        }
        .total{
            padding: 0 100px 0 10px;
            span{
                font-size: 26px;
                color: #39394D;
            }
        }
    }
    .ctnOne{
        background-color: #fff;
        width: 100%;
        padding: 8px 40px;
        .zxjAccountNumber{
            display: flex;
            justify-content: space-between;
            padding: 18px 0 24px 0;
            border-bottom: 2px solid #E9E9E9;
            h3{
                font-size: 32px;
                color: #242424;
            }

        }
        .remarks{
            width: 100%;
            text-align: right;
            color: #CCCCCC;
            font-size: 14px;
        }
        .jietu{
            h3{
                color: #999999;
                font-size: 30px;
            }
        .van-icon{
         position: relative;
         top: 4px;
     }
        }
    }
}
footer{
    width: 100%;
    padding: 30px;
    color: #CCCCCC;
  background-color: #f5f5f5;
    // line-height: 43px;
    font-size: 26px;
}
// 弹性布局
.flex{
    display: flex;
    justify-content: space-between;
    .text{
        font-size: 24px;
      color: $YellowFontColor !important;
      border: 1px solid $YellowFontColor;
      //background: #FFF2E6;
      height: 56px;
      width: 112px;
      line-height: 56px;
      text-align: center;
    }
}
// 圆形
.circular{
    width: 18px;
    height: 18px;
    background: #fda405;
    border-radius: 50%;
    position: relative;
    top: 50%;
    margin-top: -9px;
    margin-right: 8px;
}
// 外边距
.margin{
    margin: 18px 0;
}
.quHuoInfo{
    border-bottom: 2px solid #E9E9E9 ;
     padding: 20px 0;
    h3{
            font-size: 32px;
            color: #212121;
        }
}
// 内容左侧文字颜色
.flexLeftColor{
    color: #999999;
    font-size: 28px;
}
// 内容右侧文字颜色
.flexRightColor{
    font-size: 28px;
    color: #212121;
    text-align: right;
}
.isFetched{
  font-size: 24px;
  color: $YellowFontColor !important;
  border: 1px solid $YellowFontColor;
  //background: #E6F4FF;
  height: 56px;
  width: 112px;
  line-height: 56px;
  text-align: center;
}
.greenState {
  border: 1px solid #02AD3D !important;
  color: #02AD3D !important;
}
.upload{
    width: 140px;
    height: 150px;
    position: relative;
    .shu{
        width: 1px;
        height: 50px;
        background-color: #1A1A1A;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -25px;
    }
    .heng{
        width: 50px;
        height: 1px;
        background-color: #1A1A1A;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -25px;
    }
}
.remarks{
    font-size: 38px;
}
</style>
